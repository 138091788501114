<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: V36SF0906A.vue
 Projeto: SaaSERP2
 Criação do arquivo: 14/04/2022 - 12:49:02
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:41:47
 Modificado por: pedro.rosa
-->
<!-- <template>
  <div>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="7"
          sm="12"
        >
          <v-card
            height="70"
            :style="{ marginBottom: '10px' }"
          >
            <v-card-text>
              <v-autocomplete
                v-model="telaEscolhida"
                :items="TelasDisponiveis"
                label="Configuração"
                outlined
                dense
              />
            </v-card-text>
          </v-card>
          <div v-if="telaEscolhida === 'Filtros Rápidos'">
            <config-filtros-rapidos
              :label="this.$t('ConfigTelas.filtrosRapidos')"
            />
          </div>
          <div v-if="telaEscolhida === 'Mapa'">
            <config-mapa />
          </div>
          <div v-if="telaEscolhida === 'Tela de Apresentação'">
            <config-splash-screen />
          </div>
          <save-btn :tela-escolhida="telaEscolhida ? telaEscolhida : ''" />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <div style="display: flex; justify-content: flex-end">
            <preview :pagina="telaEscolhida ? telaEscolhida : ''" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template> -->

<template>
  <v-card flat>
    <v-card-title
      :class="`${$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'}`"
      dense
    >
      Parâmetros
    </v-card-title>
    <v-row
      class="pa-4"
      justify="space-between"
    >
      <v-col cols="3">
        <v-treeview
          :active.sync="active"
          :items="opcoesParametros"
          :open.sync="open"
          activatable
          color="primary"
          open-on-click
          transition
          dense
          return-object
          @update:active="mudaTelaParametro"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.children">
              {{ item.icon }}
            </v-icon>
            <v-icon v-else>
              {{ open ? "folder_open" : "folder" }}
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <span>
              {{ item.name }}
            </span>
          </template>
        </v-treeview>
      </v-col>

      <v-divider vertical />

      <v-col
        class="text-center"
        cols="9"
      >
        <v-scroll-y-transition mode="out-in">
          <component
            :is="selecionado.component"
            v-if="selecionado && selecionado.component"
          />
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
//import preview from "../preview/index.vue";
import ConfigFiltrosRapidos from "./SaaSTEC/ConfigFiltrosRapidos";
//import ConfigMapa from "./ConfigMapa";
import ConfigSplashScreen from "./SaaSTEC/ConfigSplashScreen";
//import saveBtn from "./SaveBtn";
import Tema from "./SaaSTEC/Tema";
import Marketplace from "./SaaSTEC/Marketplace";
export default {
  components: {
    //preview,
    //ConfigFiltrosRapidos,
    //ConfigMapa,
    //ConfigSplashScreen,
    //saveBtn,
  },
  data() {
    return {
      telaEscolhida: null,
      TelasDisponiveis: ["Filtros Rápidos", "Tela de Apresentação"],
      active: [],
      open: [],
      selecionado: null,
      opcoesParametros: [
        {
          name: "SaaSTEC",
          children: [
            {
              id: "SaaSTEC-1",
              name: "Tema",
              icon: "photo",
              component: Tema,
            },
            {
              id: "SaaSTEC-2",
              name: "Marketplace",
              icon: "store",
              component: Marketplace,
            },
            {
              id: "SaaSTEC-3",
              name: "Filtros rápidos",
              icon: "filter_alt",
              component: ConfigFiltrosRapidos,
            },
            {
              id: "SaaSTEC-4",
              name: "Tela de apresentação",
              icon: "phone_iphone",
              component: ConfigSplashScreen,
            },
          ],
        },
      ],
    };
  },
  methods: {
    mudaTelaParametro(selecionado) {
      this.selecionado = selecionado[0];
    },
  },
};
</script>

<style lang="scss" src="./style.scss"/>