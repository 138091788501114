<template>
  <v-card
    flat
    min-width="80%"
    :loading="loading"
    :disabled="loading"
  >
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="10"
          md="5"
        >
          <v-text-field
            v-model="nmComercial"
            filled
            dense
            flat
            label="Nome comercial"
            placeholder="Digite o nome da empresa"
          />
        </v-col>
        <v-col
          cols="2"
          md="1"
          :style="{
            marginTop: '12px',
            marginLeft: '-10px'
          }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>Nome comercial que aparecerá no marketplace SaaSTEC</span>
          </v-tooltip>
        </v-col>
        <!-- <v-col
          cols="10"
          md="5"
        >
          <v-text-field
            v-model="nmSubdominio"
            filled
            dense
            flat
            label="Subdomínio"
            placeholder="Digite o subdomínio em que a aplicação será acessada"
          >
            <template #append>
              <span :style="{ marginTop: '16px' }">.saastecerp.com.br</span>
            </template>
          </v-text-field>
        </v-col> -->
        <!-- <v-col
          cols="2"
          md="1"
          :style="{
            marginTop: '12px',
            marginLeft: '-10px'
          }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>Subdomínio em que a aplicação será acessada</span>
          </v-tooltip>
        </v-col> -->
      </v-row>
      <v-row no-gutters>
        <v-col cols="4">
          <!-- <v-switch
            v-model="idMktplc"
            inset
            label="Permitir que sua empresa apareça em nosso marketplace"
          /> -->
          <v-switch
            v-model="idPrddis"
            inset
            label="Permitir que o usuário visualize em quais lojas o produto está disponível"
          />
          <v-switch
            v-model="idFiltro"
            inset
            label="Ativar filtros rápidos de ofertas"
          />
          <v-switch
            v-model="idSplash"
            inset
            label="Ativar tela de apresentação da sua empresa na inicialização do app"
          />
          <!-- <v-switch
            v-model="idMapa"
            inset
            label="Ativar mapa de localização dos produtos na loja"
          /> -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row
        align="center"
        justify="end"
        no-gutters
      >
        <v-btn
          depressed
          color="primary"
          @click="saveParams"
        >
          Salvar
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      nmComercial: "",
      nmSubdominio: "",
      idMktplc: false,
      idPrddis: false,
      idFiltro: false,
      idSplash: false,
      idMapa: false,
      loading: true,
    }
  },
  async mounted() {
    await this.getParams()
  },
  methods: {
    async getParams() {
      await this.request({
        metodo: 'get',
        sp: 'SP_CE360900013',  
        params: {},      
      })
        .then((response) => {
          const res = response.database && response.database[0] ? response.database[0] : null
          this.nmComercial  = res.tc000_nm_inst
          this.nmSubdominio = res.tc000_ds_urlprd.split('.')[0]
          this.idMktplc = res.tc000_id_mktplc && res.tc000_id_mktplc == 1 ? true : false
          this.idPrddis = res.tc000_id_prddis && res.tc000_id_prddis == 1 ? true : false
          this.idFiltro = res.tc000_id_filtro && res.tc000_id_filtro == 1 ? true : false
          this.idSplash = res.tc000_id_splash && res.tc000_id_splash == 1 ? true : false
          this.idMapa = res.tc000_id_mapa   && res.tc000_id_mapa == 1 ? true : false
        })
        .finally(() => this.loading = false)
    },
    async saveParams() {
      this.loading = true
      let obj = {
        _rENT_TP_OPERAC: "2",
        _rENT_NM_INST  : this.nmComercial,
        _rENT_DS_URLPRD: this.nmSubdominio + '.saastecerp.com.br',
        _rENT_DS_URLHML: this.nmSubdominio + 'homol.saastecerp.com.br',
        _rENT_ID_MKTPLC: this.idMktplc ? 1 : 2,
        _rENT_ID_PRDDIS: this.idPrddis ? 1 : 2,
        _rENT_ID_SPLASH: this.idSplash ? 1 : 2,
        _rENT_ID_MAPA  : this.idMapa ? 1 : 2,
        _rENT_ID_FILTRO: this.idFiltro ? 1 : 2,
      }
      await this.request({
        metodo: 'get',
        sp: 'SP_CE360900013',  
        params: obj,      
      })
        .then((res) => {
          if (res) {
            this.notification().success({
              titulo: 'Parâmetros salvos com sucesso',
              mensagem: 'Os parâmetros foram salvo com sucesso!',
              timeout: 4000,
            })
          }
        })
        .catch((err) => console.error(err))
        .finally(() => this.loading = false)
    },
  },
}
</script>

<style>

</style>