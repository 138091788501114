<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: index.vue
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:41:01
 Modificado por: pedro.rosa
-->
<template>
  <div>
    <v-card
      flat
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title>
        Configurações Tela de Apresentação
        <v-spacer />
      </v-card-title>
      <v-form>
        <v-container>
          <v-row>
            <!-- <v-col cols="12">
              <v-file-input
                show-size
                type="file"
                accept="image/*"
                counter
                label="Logotipo"
                filled
                dense
                flat
                @change="receiveFotoLogo"
              />
            </v-col> -->
            <v-col cols="12">
              <v-file-input
                show-size
                counter
                type="file"
                accept="image/*"
                label="Imagem de Fundo"
                filled
                dense
                flat
                @change="receiveFotoBackground"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="slogan"
                label="Slogan"
                prepend-icon="message"
                filled
                dense
                flat
              />
            </v-col>
            <v-col cols="4">
              <v-menu
                transition="slide-x-transition"
                bottom
                left
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    depressed
                    height="52"
                    width="100%"
                    v-on="on"
                  >
                    <v-icon> palette </v-icon> Cor do slogan
                  </v-btn>
                </template>
                <v-card>
                  <v-container>
                    <v-color-picker
                      v-model="corSlogan"
                      hide-inputs
                    />
                  </v-container>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="alinhamentoEscolhido"
                label="Posição"
                prepend-icon="vertical_distribute"
                filled
                dense
                flat
                :items="alinhamentos"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="tempoSplash"
                label="Tempo"
                prepend-icon="schedule"
                filled
                dense
                flat
                suffix="Segundos"
                :items="[1, 3, 5, 10]"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-card-actions>
            <v-row
              align="center"
              justify="end"
              no-gutters
            >
              <v-btn
                depressed
                color="primary"
                @click="salvaInfoSplash"
              >
                Salvar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      corSlogan: "#f49636",
      ativarSplash: false,
      tempoSplash: 5,
      interval: null,
      isPlaying: false,
      progress: 0,
      value: 0,
      valorImagem: null,
      valorLogo: null,
      replay: false,
      alinhamentos: ["Cabeçalho", "Centralizado", "Rodapé"],
      alinhamentoEscolhido: "Centralizado",
      slogan: "",
      loading: true,
    };
  },
  computed: {
    ...mapState([
      "primeiraConfig",
      "tc000",
      "configSaved",
      "sloganSplash",
      "alinhamentoSplash",
      "backgroundSplash",
    ]),
    color() {
      if (this.tempoSplash < 2) return "indigo";
      if (this.tempoSplash < 4) return "teal";
      if (this.tempoSplash < 6) return "green";
      if (this.tempoSplash < 8) return "orange";
      return "red";
    },
    animationDuration() {
      return `${60 / this.tempoSplash}s`;
    },
  },
  watch: {
    configSaved() {
      this.carregaSplash();
      this.vuexSet({ value: false, stateName: "configSaved" });
    },
    ativarSplash() {
      this.vuexSet({ value: this.ativarSplash, stateName: "ativarSplash" });
    },
    slogan: function () {
      let novoSlogan = this.slogan;
      this.vuexSet({ value: novoSlogan, stateName: "sloganSplash" });
      this.handleFirstConfig();
    },
    alinhamentoEscolhido: function () {
      let novoAlign = this.alinhamentoEscolhido;
      this.vuexSet({ value: novoAlign, stateName: "alinhamentoSplash" });
      this.handleFirstConfig();
    },
    corSlogan: {
      handler() {
        let novaCorSlogan = this.corSlogan;
        this.vuexSet({ value: novaCorSlogan, stateName: "corSlogan" });
        this.handleFirstConfig();
      },
    },
    tempoSplash() {
      this.vuexSet({ value: this.tempoSplash, stateName: "tempoSplash" });
    },
  },
  async mounted() {
    await this.carregaSplash();
    await this.handleFirstConfig();
  },

  methods: {
    ...mapActions(["vuexSet"]),
    decrement() {
      this.tempoSplash--;
    },
    increment() {
      this.tempoSplash++;
    },

    playVideo() {
      this.vuexSet({ value: this.tempoSplash, stateName: "playAnimation" });
      this.isPlaying = !this.isPlaying;
      this.interval = setInterval(() => {
        if (this.progress >= 100) {
          clearInterval(this.interval);
          this.progress = 0;
          this.replay = true;
          this.isPlaying = false;
        } else {
          this.progress += 100 / this.tempoSplash;
          let tempoSlide = this.tempoSplash;
          this.vuexSet({ value: tempoSlide, stateName: "playAnimation" });
        }
      }, 1000);
    },
    replayVideo() {
      this.vuexSet({ value: null, stateName: "playAnimation" });
      this.replay = !this.replay;
      this.isPlaying = false;
      setTimeout(() => {
        this.playVideo();
      }, 1000);
    },
    async receiveFotoLogo(foto) {
      try {
        let logoParceiro = await this.imageToBase64(foto);
        this.valorLogo = logoParceiro;
        this.vuexSet({ value: logoParceiro, stateName: "logoSplash" });
        this.handleFirstConfig();
      } catch {
        //
      }
    },
    async receiveFotoBackground(foto) {
      try {
        let backgroundParceiro = await this.imageToBase64(foto);
        this.valorImagem = backgroundParceiro;
        this.vuexSet({
          value: backgroundParceiro,
          stateName: "backgroundSplash",
        });
        this.handleFirstConfig();
      } catch {
        //
      }
    },
    handleFirstConfig() {
      if (this.primeiraConfig) {
        var alinhamentoDB = null;
        /* eslint-disable no-unused-vars */
        switch (this.alinhamentoEscolhido) {
        case "Centralizado":
          alinhamentoDB = 1;
          break;
        case "Cabeçalho":
          alinhamentoDB = 2;
          break;
        case "Rodapé":
          alinhamentoDB = 3;
          break;
        }
        this.tc000.map((r) => {
          r._rTC000_IM_LOGO = this.valorLogo;
          r._rTC000_IM_SPLASH = this.valorImagem;
          r._rTC000_DS_SPLASH = this.slogan;
          r._rTC000_CD_COR = this.corSlogan;
          r._rTC000_ID_POS = alinhamentoDB;
          r._rTC000_QT_SEGSPL = this.tempoSplash;
          r._rTC000_ID_SPLASH = this.ativarSplash ? 1 : 2;
        });

        this.vuexSet({ value: this.ativarSplash, stateName: "ativarSplash" });
      }
    },
    async carregaSplash() {
      try {
        this.loading = true;
        const retorno = await this.request({
          metodo: "get",
          sp: "SP_CE360900001",
          params: {},
        });

        if (retorno.database.length > 0) {
          this.valorLogo = retorno.database[0].tc000_im_logo;
          this.valorImagem = retorno.database[0].tc000_im_splash;
          let urlLogo = retorno.database[0].tc000_im_logo
            ? `data:image/png;base64,${retorno.database[0].tc000_im_logo}`
            : null;
          let urlImagem = retorno.database[0].tc000_im_logo
            ? `data:image/png;base64,${retorno.database[0].tc000_im_splash}`
            : null;
          this.slogan = retorno.database[0].tc000_ds_splash;
          this.corSlogan = retorno.database[0].tc000_cd_cor;
          this.ativarSplash =
            retorno.database[0].tc000_id_splash === 1 ? true : false;
          this.tempoSplash = retorno.database[0].tc000_qt_segspl;
          this.alinhamentoEscolhido =
            retorno.database[0].tc000_id_pos === 1
              ? "Centralizado"
              : retorno.database[0].tc000_id_pos === 2
                ? "Cabeçalho"
                : "Rodapé";

          this.vuexSet({ value: urlLogo, stateName: "logoSplash" });
          this.vuexSet({ value: urlImagem, stateName: "backgroundSplash" });
        }
      } catch (error) {
        console.error(error);
        this.notification().error({erro: "Erro ao carregar parâmetros"});
      } finally {
        this.loading = false;
      }
    },
    async salvaInfoSplash() {
      this.loading = true;
      const obj = {
        _rENT_TP_OPERAC: 2,
        _rENT_IM_SPLASH: this.backgroundSplash.split(",")[1],
        _rENT_DS_SPLASH: this.sloganSplash,
        _rENT_CD_COR: this.corSlogan.hex || this.corSlogan,
        _rENT_ID_POS:
          this.alinhamentoSplash === "Centralizado"
            ? 1
            : this.alinhamentoSplash === "Cabeçalho"
              ? 2
              : 3,
        _rENT_QT_SEGSPL: this.tempoSplash,
      };
      await this.request({
        metodo: "post",
        sp: "SP_CE360900014",
        params: obj,
      })
        .then((res) => {
          if (res) {
            this.notification().success({mensagem: "As configurações de tela de apresntação foram salvas!"});
          }
        })
        .catch((err) => console.error(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>