<template>
  <v-card
    class="pt-6 mx-auto"
    flat
    min-width="80%"
    :loading="loadingConfig"
    :disabled="loadingConfig"
  >
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6">
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-col
              cols="3"
              :style="{ marginRight: '20px' }"
            >
              Logo da empresa que será mostrado no app.
            </v-col>
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-avatar
                  tile
                  size="258"
                  color="grey lighten-3"
                >
                  <v-img
                    v-if="logoImage"
                    :src="logoImage"
                    class="mb-6"
                  />
                  <v-fade-transition>
                    <v-overlay
                      v-show="hover"
                      absolute
                      color="primary"
                    >
                      <v-file-input
                        :rules="fileInputRules"
                        accept="image/*"
                        prepend-icon="edit"
                        dense
                        :style="{ marginLeft: '10px', marginRight: '10px' }"
                        @change="atualizaImagem"
                      />
                    </v-overlay>
                  </v-fade-transition>
                </v-avatar>
              </template>
            </v-hover>
          </v-row>
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-btn
              v-if="logoImage"
              depressed
              :style="{ marginLeft: '160px' }"
              @click="logoImage = null"
            >
              Remover logo
            </v-btn>
          </v-row>
        </v-col>
        <v-col
          cols="6"
        >
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-color-picker
              v-model="corPrimaria"
              dot-size="10"
              mode="hexa"
              swatches-max-height="100"
            />
            <v-col
              cols="3"
              :style="{ marginLeft: '20px' }"
            >
              Cor primária do app, onde mudará a cor dos botões, cabeçalhos e detalhes na aplicação.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row
        align="center"
        justify="end"
        no-gutters
      >
        <v-btn
          depressed
          color="primary"
          @click="salvarLogoECor"
        >
          Salvar
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
//import previewHeader from '../../../preview/headerBar'
export default {
  components: {
    //previewHeader,
  },
  data() {
    return {
      loadingConfig: true,
      logoImage: null,
      fileInputRules: [
        value => !value || value.size <= 200000 || 'Logo deve ter tamanho igual ou menor a 200KB!',
      ],
      corPrimaria: null,
    }
  },
  created() {
    this.carregaLogoECor()
  },
  methods: {
    async atualizaImagem(file) {
      this.logoImage = await this.imageToBase64(file)
    },
    async carregaLogoECor() {
      try {
        this.loadingConfig = true
        let respostaConfig = await this.request({
          metodo: 'get',
          sp: 'SP_CE360900012',
          params: { _rENT_TP_OPERAC: 1 },
        })
        if(respostaConfig) {
          respostaConfig = respostaConfig.database[0]
          this.logoImage = respostaConfig.tc000_im_logo ? `data:image/png;base64,${ respostaConfig.tc000_im_logo }` : null
          this.corPrimaria = respostaConfig.tc000_cd_corpri || null
        }
      } finally {
        this.loadingConfig = false
      }
    },
    async salvarLogoECor() {
      try {
        this.loadingConfig = true
        let respostaConfig = await this.request({
          metodo: 'post',
          sp: 'SP_CE360900012',
          params: { _rENT_IM_LOGO: this.logoImage ? this.logoImage.split('base64,')[1] : null, _rENT_CD_CORPRI: this.corPrimaria.hex || this.corPrimaria, _rENT_TP_OPERAC: 2 },
        })
        if(respostaConfig) {
          respostaConfig = respostaConfig.database[0]
          this.logoImage = respostaConfig.tc000_im_logo ? `data:image/png;base64,${ respostaConfig.tc000_im_logo }` : null
          this.corPrimaria = respostaConfig.tc000_cd_corpri || null
        }
      } finally {
        this.loadingConfig = false
      }
    },
  },
}
</script>

<style>
</style>